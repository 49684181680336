<template>
  <div class="flex flex-col w-full">
    <div class="container mx-auto">
      <nav class="flex w-full justify-between px-2 font-bold">
        <div v-bind:key="tab"
             @click="changeTab(tab)"
             v-for="tab in tabs"
             :class="tab.active ? 'border-blue-normal cursor-default' : 'border-transparent cursor-pointer hover:border-blue-dark hover:text-blue-dark'"
             class="p-2 border-b-4 duration-500">
          <span :class="tab.active ? 'text-blue-normal' : ''">
            {{ tab.title }}
          </span>
        </div>
      </nav>
    </div>

    <div class="container mx-auto mt-4 overflow-scroll w-full h-96 scrollbar pl-1">
      <div v-bind:key="card"
           v-for="card in currentTab.content"
           class="flex h-80 bg-blue-normal rounded-2xl p-6 mb-4 mx-1">
        <div class="m-auto text-center text-white">
          <img :class="card.icon ? '' : 'hidden'"
               :src="card.icon"
               class="mx-auto w-24 h-24 mb-4"
               alt="">
          <h2 class="font-bold">
            {{ card.heading }}
          </h2>
          <p>
            {{ card.body }}
          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'TabComponent',

  data () {
    const tabs = [
      {
        title: 'Services',
        active: true,
        content: [
          {
            icon: '',
            heading: 'Talent Acquisition',
            body: 'Specializing in recruiting candidates for production, HORECA, and IT industries'
          },
          {
            icon: '',
            heading: 'Recruitment Services',
            body: 'Providing complete recruitment solutions, from sourcing to selection on English, Ukrainian, Russian languages'
          },
          {
            icon: '',
            heading: 'Staff Development',
            body: 'Offering seminars for recruiters on competency assessment, selection techniques, and effective interviewing methods'
          },
          {
            icon: '',
            heading: 'Branding and Marketing for HR',
            body: 'Developing and executing strategies for building a strong and effective HR brand.'
          }
        ]
      },
      {
        title: 'Portfolio',
        active: false,
        content: [
          {
            icon: '',
            heading: '',
            body: 'Executed a comprehensive recruitment and onboarding process for over 250 employees in the HORECA, warehouse, and production sectors.'
          },
          {
            icon: '',
            heading: '',
            body: 'Successfully filled vacancies at the 5-star Kempinski Hungary hotel, hiring supervisors, cooks, maids and dishwashers.'
          },
          {
            icon: '',
            heading: '',
            body: 'Led the planned recruitment of 1000 workers across 7 different partner companies under my leadership.'
          },
          {
            icon: '',
            heading: '',
            body: 'Developed marketing materials and presentations for Workant Kft, including a complete brand development on the YouTube platform. This included the full cycle of video production, such as pre-production, filming, and post-production.'
          },
          {
            icon: '',
            heading: '',
            body: 'Trained over 5 recruiters and provided detailed training for those new to the HR field, resulting in the successful hiring of more than 750 employees.'
          }
        ]
      },
      {
        title: 'Portfolio',
        active: false,
        content: [
          {
            icon: require('@/assets/icons/workant.svg'),
            heading: '',
            body: 'Management of the marketing and recruiting departments of the international recruiting company Workant kft from 2019 - 2023'
          }
        ]
      }
    ]
    return {
      tabs,
      currentTab: tabs[0]
    }
  },

  methods: {
    changeTab (tab) {
      this.currentTab.active = false
      this.currentTab = tab
      this.currentTab.active = true
    }
  }
}
</script>
