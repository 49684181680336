<template>
  <metainfo/>

  <div
    class="max-h-screen min-h-screen overflow-y-scroll scrollbar">
    <div class="pt-32 text-center container mx-auto">
      <div class="flex">
        <img alt="Talent Acquisition Manager Yurii Chernenko"
             class="mx-auto rounded-3xl w-28"
             src="./assets/img/yuriichernenko.jpg">
      </div>
      <h1 class="mt-10 font-black text-4xl">Yurii Chernenko</h1>
      <h2 class="mt-4 text-gray-500">Talent Acquisition Manager</h2>
    </div>

    <div class="flex justify-between container mx-auto mt-10 mb-20 px-2">
      <ContactButton :link=linkedin>
        <img alt="write me on Linkedin" class="w-6"
             src="./assets/icons/li.svg">
      </ContactButton>
      <ContactButton :link=telegram>
        <img alt="write me on telegram" class="w-6"
             src="./assets/icons/tg.svg">
      </ContactButton>
      <ContactButton :link=email>
        <img alt="write me on email" class="w-6"
             src="./assets/icons/mail.svg">
      </ContactButton>
    </div>

    <TabComponent/>

    <div class="fixed bottom-0 left-0 right-0 h-10 bg-yellow">
      <a class="h-full flex justify-center items-center text-dark hover:text-blue-dark duration-500"
         href="https://u24.gov.ua/"
         target="_blank">
        <span class="font-black">
          Stand with Ukraine
        </span>
        <img alt="Ukraine flag" class="ml-2 h-4" src="./assets/icons/ua.svg">
      </a>
    </div>
  </div>

</template>

<script>
import ContactButton from '@/components/ContactButton'
import { useMeta } from 'vue-meta'
import TabComponent from '@/components/TabComponent.vue'

export default {
  name: 'App',
  setup () {
    useMeta({
      title: 'Talent Acquisition Manager — Yurii Chernenko',
      htmlAttrs: { lang: 'en-US', amp: true }
    })
  },

  components: {
    TabComponent,
    ContactButton
  },

  data () {
    return {
      // instagram: 'https://www.instagram.com/yuriichernenko_/',
      linkedin: 'https://www.linkedin.com/in/yurii-chernenko/',
      telegram: 'https://t.me/yuriichernenko/',
      email: 'mailto:yuriichernenkoua@gmail.com'
    }
  }
}
</script>
