import { createApp } from 'vue'
import { createMetaManager, plugin as vueMetaPlugin } from 'vue-meta'
import App from './App.vue'
import './assets/tailwind.css'

const app = createApp(App)
  .use(createMetaManager())
  .use(vueMetaPlugin)

app.mount('#app')
