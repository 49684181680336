<template>
  <a :href=link target="_blank"
     class="flex bg-blue-normal rounded-xl w-24 h-16 justify-center items-center hover:bg-blue-dark duration-500">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ContactButton',

  props: {
    link: {
      type: String
    }
  }
}
</script>
